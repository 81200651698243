<template>
  <a-modal title="菜单权限详情" @ok="submit" :confirm-loading="loading" v-model:visible="flags.visible"
    @cancel="flags.visible = false;" @close="flags.visible = false;">
    <div class="modal-wrap">
      <a-tree :fieldNames="{ children: 'sub_menu', key: 'id', title: 'mc' }" v-model:expandedKeys="expandedKeys"
        v-model:selectedKeys="selectedKeys" checkStrictly v-model:checkedKeys="checkedKeys" checkable :tree-data="options">
      </a-tree>
    </div>
  </a-modal>
</template>
<script>
import service from '@/service/service';
import { message } from 'ant-design-vue';

export default {
  data() {
    return {
      expandedKeys: [],
      checkedKeys: {
        checked:[],
      },
      selectedKeys: [],
      options: [],
      form: {
        id: '',
      },
      flags: {
        visible: false,
      },
      loading: false,
    }
  },
  methods: {
    async init(id) {
      this.form.id = id;
      this.flags.visible = true;
      this.loading = true;
      const res = await service.get_menu_lists();
      const { data: defaultList } = await service.get_role_menu({ role_id: this.form.id });
      this.loading = false;
      this.options = res.data;
      if (defaultList.length) {
        this.checkedKeys.checked =  defaultList.map(m=>m.id);
      }
      else {
        this.checkedKeys.checked = [1]
      }
    },
    async submit() {
      this.loading = true;
      const res = await service.set_role_menu({ role_id: this.form.id, menu_id: this.checkedKeys.checked.join(',') });
      this.loading = false;
      if (res.code) {
        message.success(res.msg || '成功');
        this.$emit('refreshList');
        this.flags.visible = false;
      }
      else {
        message.error(res.msg || '失败');
      }
    }
  }
}
</script>
<style lang="less" scoped>
.modal-wrap {}
</style>